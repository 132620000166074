import React from 'react';
import { Fragment } from 'react';

export const FourOFour = () => {
  return (
    <Fragment>
      <div style={{ marginTop: '250px' }} className='heading'>
        404
      </div>
    </Fragment>
  );
};
